export const firstSafetyMototoLists = [
  {
    title: "transparency & visibility",
    featureList: [
      "manage the crisis by communicating periodically and on-demand with authentic information on ways of working and operating guidelines.",
      "enable transparent and 2-way communications on leadership strategy, important Standard Operating Procedures (SOPs) and mission-critical objectives.",
    ],
  },
  {
    title: "business readiness & risk Index(es)",
    featureList: [
      "conduct pulse checks on matters of interest – from personal wellness to safety to business risk assessment.",
      "use 360 surveys to create actionable insights which help teams and individuals answer questions at each stage of transitioning to the ‘next normal’.",
    ],
  },
  {
    title: "accountability & productivity",
    featureList: [
      "enable clarity of dynamic work priorities for a distributed workforce. Empower employees to align their goals against team and organization goals, on-demand.",
      "manage tasks most critical to the mission your employees are on.",
    ],
  },
];
