import * as React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { BiChevronRight } from "react-icons/bi";
import Layout from "../../../containers/global/layout";
import Seo from "../../../components/seo";
import BannerText from "../../../components/BannerText";
import Banner from "../../../containers/Banner";
import { FirstsafetyImageFeature, SideBottomImage, TeamBackImg, Rombus } from "../../../images/index";
import Button from "../../../components/Button";
import MotoList from "../../../containers/MotoList";
import CheckItOut from "../../../containers/CheckItOut";
import LookingForInspiration from "../../../containers/LookingForInspiration";
import ImageFeatureBlock from "../../../components/ImageFeatureBlock";
import FeatureList from "../../../containers/FeatureList";
import { featureData } from "../../../constants/first-safety/firstSafety-feature-data";
import { firstSafetyMototoLists } from "../../../constants/first-safety/firstsafety-moto-list";
import BreadCrumb from "../../../containers/BreadCrumb";
import Container from "../../../utils/Container";

const breadCrumbItems = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "solutions",
    link: "/solutions",
  },
  {
    text: "first safety",
    link: "",
  },
];

const WorkplaceSafety = ({ data }) => {
  const firstSafetyBanner = data.firstSafetyBanner.nodes[0];
  const FirstSafetyInspiration = data.FirstSafetyInspiration.nodes;
  const firstSafetyInspirationFooter = data.firstSafetyInspirationFooter.nodes[0];
  return (
    <Layout>
      <Seo title="Solutions-Workplace-safety" />
      <Banner
        bgImg={firstSafetyBanner.desktopImage.gatsbyImageData}
        mobImg={firstSafetyBanner.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[24%] lg:left-16 mx-24 sm:mx-0 2xl:left-28 md:top-48 md:left-12 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 w-full sm:w-1/2 -mt-20 md:-mt-40 xl:-mt-16">
          <BannerText
            title={
              <h2 className="text-3xl lg:text-4xl font-bold sm:text-normal xl:text-5xl">
                <strong>first safety</strong>
                <br />
              </h2>
            }
            subTitle={
              <p className="xl:w-full text-[#000000] font-medium lg:text-3xl xl:text-5xl text-3xl">
                return-to-work solutions
              </p>
            }
          />
          <Button
            link="https://entomo.co/request-demo/"
            text="get started today"
            style={{ backgroundColor: "#000", color: "#ffda00", fontSize: "16px" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
      <Container>
        <BreadCrumb breadCrumbItems={breadCrumbItems} style={{ margin: "2% 1rem" }} />
      </Container>
      <ImageFeatureBlock
        sideImg={FirstsafetyImageFeature}
        sideBottomImage={SideBottomImage}
        heading="safety and trust multiply the dividends
        of performance in the #NextNormal"
        subTitle="to survive and thrive in the future, especially post-outbreak, organisations need:"
        featureDataList={[
          "a clear view of challenges to safety of lives and livelihoods",
          "need to ensure compliance to disaster management and business continuity guidelines",
          "the right data insights to make ‘lifeboat’ choices and a clear plan for a distributed workforce",
          "visibility to the variables that can influence and impact productivity and performance",
          "a view of the mission critical enterprise practices to remain competitive and the changes that are needed to sustain capacity and abilities to perform",
        ]}
      />
      <div className="bg-black">
        <MotoList featureList={firstSafetyMototoLists} />
      </div>
      <Container>
        <FeatureList featureData={featureData} />
      </Container>
      <CheckItOut
        bgImg={TeamBackImg}
        heading="for small teams working
      on game-changing
      innovations"
        description="explore our pre-configured editions that are designed to help you accomplish targeted objectives."
      />
      <LookingForInspiration
        title="looking for inspiration?"
        sideBottomImage={SideBottomImage}
        sideLeftImage={Rombus}
        description="look no further. See how some of our customers are taking performance management to a whole new level."
        storyData={[
          {
            image: FirstSafetyInspiration[0].storyOne.gatsbyImageData,
            text: "Democratized insights, gamification for accelerated productivity for 24,000-plus employees",
            fullStoryLink:
              "https://entomo.co/success-stories/democratized-insights-gamification-for-accelerated-productivity-for-24000-plus-employees/",
          },
          {
            image: FirstSafetyInspiration[0].storyTwo.gatsbyImageData,
            text: "Enhanced business decision-making, on-the-go sales team tracking for over 100 branches and sub-branches",
            fullStoryLink:
              "https://entomo.co/success-stories/enhanced-business-decision-making-on-the-go-sales-team-tracking-for-over-100-branches-and-sub-branches/",
          },
        ]}
      />
      <Banner
        height="auto"
        bgImg={firstSafetyInspirationFooter.desktopImage.gatsbyImageData}
        mobImg={firstSafetyInspirationFooter.mobileImage.gatsbyImageData}
      >
        <div className="absolute top-[8%] 2xl:left-28 sm:w-1/2 md:left-12 lg:left-20 left-1.5 md:top-48 2xl:top-1/3 xl:top-1/3 2xl:px-[15px] xl:left-32 flex flex-col pb-[2%] text-[40px] text-center sm:text-left sm:-mt-0 -mt-20 w-fit mx-8 sm:mx-0 md:-mt-40 xl:-mt-16 lg:top-52 font-extrabold ">
          <BannerText
            title={
              <h2 className="mt-16 sm:mt-4 text-white text-3xl 2xl:text-5xl md:text-2xl xl:text-4xl">
                <strong>start with the basics</strong>
              </h2>
            }
            subTitle={
              <p className="text-white text-lg sm:w-72 font-normal xl:text-2xl">
                connect with our team to get an in-depth understanding of all the features.
              </p>
            }
          />
          <Button
            link="https://entomo.co/contact/"
            text="book a demo"
            style={{ backgroundColor: "#FFDA00", color: "#000000" }}
            sideImg={<BiChevronRight />}
          />
        </div>
      </Banner>
    </Layout>
  );
};
WorkplaceSafety.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};
export default WorkplaceSafety;
export const query = graphql`
  query {
    firstSafetyBanner: allContentfulBannerContent(filter: { heading: { eq: "Global firstsafety banner" } }) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    FirstSafetyInspiration: allContentfulLookingForInspiration(
      filter: { heading: { eq: "Global solution First safety" } }
    ) {
      nodes {
        storyOne {
          gatsbyImageData(formats: WEBP)
        }
        storyTwo {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
    firstSafetyInspirationFooter: allContentfulBannerContent(
      filter: { heading: { eq: "Solutions everyday performance footer banner" } }
    ) {
      nodes {
        desktopImage {
          gatsbyImageData(formats: WEBP)
        }
        mobileImage {
          gatsbyImageData(formats: WEBP)
        }
      }
    }
  }
`;
