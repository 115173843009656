import { FirstsafetyFeatureOne, FirstsafetyFeatureTwo, FirstsafetyFeatureThree } from "../../images";

export const featureData = [
  {
    title: "create a safe workplace",
    featureList: [
      "ensure and sustain safety in workplace with authentic communication.",
      "get daily alerts and insights on the situation and norms to be adopted. Provide timely, contextual and actionable recommendations to nudge the right action.",
      "check employee well-being regularly.",
    ],
    image: FirstsafetyFeatureOne,
  },
  {
    title: "engage workers in managing workplace safety and operational risks",
    featureList: [
      "engage workers in managing workplace safety and operational risks",
      "daily alerts on ongoing safety norms and red zones.",
      "self-assessments and reporting of risk exposure.",
    ],
    image: FirstsafetyFeatureTwo,
    reversed: "true",
  },
  {
    title: "enable actionable insights to support decision makers",
    featureList: [
      "power actionable insights and recommendations – from workplace and operation risk assessment, to building capacity information, to identification of safe facilities, employee screening, among other critical data.",
      "enable clarity of dynamic work priorities for a distributed workforce. Empower employees to align their goals against team and organization goals, on-demand.",
      "create transparency on objectives and manage tasks most critical to the mission your employees are on.",
    ],
    image: FirstsafetyFeatureThree,
  },
];
